import React, { useEffect, useState } from "react";
import { Button, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import ls from "local-storage";
import style from "./scss/techsuite.module.scss";

const TechSuite = ({ HomeJson = [] }) => {
  const [activeSlide, setActiveSlide] = useState();

  const history = useHistory();

  const getIconSVG = (name) => require(`assets/images/home/icons/${name}.svg`);

  useEffect(() => {
    const activeIndex = ls.get("home_hr_tech_suite_active_tab");
    let activeElement = document.getElementById(
      `home_hr_tech_suite${
        window.innerWidth <= 768 ? "_mobile" : ""
      }_tab_${activeSlide}`
    );
    if (window.innerWidth <= 768 && !activeElement)
      activeElement = document.getElementById(
        `home_hr_tech_suite_tab_${activeSlide}`
      );
    if (!activeElement) activeElement = document.getElementById("root");
    if (activeIndex || activeIndex === 0) {
      activeElement.scrollIntoView();
      setTimeout(() => {
        ls.remove("home_hr_tech_suite_active_tab");
      }, 500);
    }
  }, [activeSlide]);

  useEffect(() => {
    const activeIndex = ls.get("home_hr_tech_suite_active_tab");
    setActiveSlide(activeIndex || HomeJson.length - 7);
  }, [HomeJson]);

  return (
    <div className={style.techSuite}>
      <center>
        <span fontas="main header">Our HR-Tech Suite</span>
      </center>
      <div>
        {HomeJson.map(({ name, content, desc, sidebg, url }, idx) => (
          <div
            key={name}
            class={idx === activeSlide ? "active" : "not_active"}
            id={`home_hr_tech_suite_tab_${idx}`}
            onClickCapture={() => setActiveSlide(idx)}
          >
            <div>
              <span>
                <Image src={getIconSVG(name)} />
                {name}
              </span>
              {idx === activeSlide && (
                <div>
                  <p fontas="sub heading">{content}</p>
                  <Image src={sidebg} alt={name} />
                  <p fontas="body">{desc}</p>
                  <Button
                    id={`home_hr_tech_suite_mobile_tab_${idx}`}
                    content="Know More"
                    onClick={() => {
                      ls.set("home_hr_tech_suite_active_tab", idx);
                      history.push(`/${url}`);
                    }}
                  />
                </div>
              )}
            </div>
            {idx === activeSlide && <Image src={sidebg} alt={name} />}
          </div>
        ))}
      </div>
    </div>
  );
};

export default TechSuite;
