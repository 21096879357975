import React from "react";
import AboutPlatform from "./AboutPlatform";
import { withTranslation } from "react-i18next";
import { Grid } from "semantic-ui-react";
import { HomeJson } from "./HomeJson";
import Header from "globals/components/Header";
import HomeSlider from "./HomeSlider";
import TechSuite from "./TechSuite";
import useScrollToTopOnMount from "globals/utils/useScrollToTopOnMount";
// import OurPartners from "./OurPartners";
import style from "./scss/home.module.scss";
import ContactForDemo from "globals/components/Contact/ContactForDemo";

const Home = (props) => {
  // const { t, changeActiveComponent } = props;
  // const isHomeScreen = true;

  // const isMobile = window.innerWidth < 480;
  const isTabletView = window.innerWidth < 992;

  useScrollToTopOnMount();

  // const handleContact = () => {
  //   window.open("https://api.whatsapp.com/send?phone=7094694226");
  // };

  return (
    <>
      <Header />
      <div className={style.noborder}>
        <div className={style.homeouter}>
          <div className={style.homeimage}>
            <Grid>
              <Grid.Column
                mobile={16}
                tablet={16}
                computer={16}
                largeScreen={9}
                widescreen={9}
              >
                <div className={style.homeleft}>
                  <span fontas="hero header">
                    One HR Tech Platform
                  </span>
                  <p className={style.homeHeadtitle} fontas="hero header">
                    Unleash Employee Potential.
                  </p>
                  <p className={style.hometitle} fontas="hero header">
                    Simply.
                  </p>
                  <div fontas="hero body">
                  Workforce Planning, Talent Transformation to Employee Engagement,
                  manage all your HR needs seamlessly in one place.
                  </div>
                  <div className={style.btnBox}>
                    {/* <Button
                      className={style.Contactbtn}
                      onClick={handleContact}
                      fontas="body"
                    >
                      Connect with Our Team
                    </Button> */}
                    <ContactForDemo />
                  </div>
                </div>
              </Grid.Column>
              <Grid.Column
                // className={style.homeright}
                largeScreen={7}
                widescreen={7}
              >
                {!isTabletView && <HomeSlider HomeJson={HomeJson} />}
              </Grid.Column>
            </Grid>
          </div>
        </div>
      </div>
      <AboutPlatform />
      <TechSuite HomeJson={HomeJson} />
      {/* <OurPartners /> */}
      {/* <OurServices
        isHomeScreen={isHomeScreen}
        classBg={true}
        column={5}
        home={true}
        json={HomeJson}
        header="Our HR-Tech Suite"
        changeActiveComponent={changeActiveComponent}
      /> */}
      {/* <Team /> */}
    </>
  );
};

export default withTranslation("translations")(Home);
