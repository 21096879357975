import React from "react";
import Slider from "react-slick";
// import { Image } from 'semantic-ui-react';
import payoda from 'assets/images/home/payoda.svg';
import landt from 'assets/images/home/landt.svg';
import shreds from 'assets/images/home/SHREDS.svg';
import appviewx from 'assets/images/home/appviewx-logo 1.svg';
import mphasis from 'assets/images/home/mphasis.svg';
import onsemi from 'assets/images/home/onsemi.svg';
import kgisl from 'assets/images/home/kgisl.svg';
import abhasha from 'assets/images/home/abhasha.webp';
import skyworks from 'assets/images/home/skyworks.webp';
import meta from 'assets/images/home/meta.webp';
import mi from 'assets/images/home/mi.webp';
import mdg from 'assets/images/home/mdg.webp';
import lti from 'assets/images/home/lti.webp';
import s from 'assets/images/home/s.webp';
import talodyn from 'assets/images/home/talodyn.webp';
import cornelius from 'assets/images/home/cornelius.webp';
import n from 'assets/images/home/n.webp';
import iopex from 'assets/images/home/iopex.webp';
import thay from 'assets/images/home/thay.webp';
import annalise from 'assets/images/home/annalise.webp';
import ciklum from 'assets/images/home/ciklum.webp';
import imagine from 'assets/images/home/imagine.webp';
import stream from 'assets/images/home/stream.webp';
import vihaan from 'assets/images/home/vihaan.webp';
import gudah from 'assets/images/home/gudah.webp';
import kl from 'assets/images/home/kl.webp';
import holistic from 'assets/images/home/holistic.webp';
import amphisoft from 'assets/images/home/amphisoft.webp';
import mmipl from 'assets/images/home/mmipl.webp';
import ioac from 'assets/images/home/ioac.webp';
import freckled from 'assets/images/home/freckled.webp';

import style from './scss/ourpartners.module.scss';

const OurPartners = () => {
    const partnersArr = [
        { image: landt, name: 'landt' },
        { image: kgisl, name: 'kgisl' },
        { image: payoda, name: 'payoda' },
        { image: onsemi, name: 'onsemi' },
        { image: shreds, name: 'shreds' },
        { image: mphasis, name: 'mphasis' },
        { image: appviewx, name: 'appviewx' },
        { image: abhasha, name: 'abhasha' },
        { image: skyworks, name: 'skyworks' },
        { image: meta, name: 'meta' },
        { image: mi, name: 'mi' },
        { image: mdg, name: 'mdg' },
        { image: lti, name: 'lti' },
        { image: s, name: 's' },
        { image: talodyn, name: 'talodyn' },
        { image: cornelius, name: 'cornelius' },
        { image: n, name: 'n' },
        { image: iopex, name: 'iopex' },
        { image: thay, name: 'thay' },
        { image: annalise, name: 'annalise' },
        { image: ciklum, name: 'ciklum' },
        { image: imagine, name: 'imagine' },
        { image: stream, name: 'stream' },
        { image: vihaan, name: 'vihaan' },
        { image: gudah, name: 'gudah' },
        { image: kl, name: 'kl' },
        { image: holistic, name: 'holistic' },
        { image: amphisoft, name: 'amphisoft' },
        { image: mmipl, name: 'mmipl' },
        { image: ioac, name: 'ioac' },
        { image: freckled, name: 'freckled' },
    ];

    const settings = {
        dots: false,
        infinite: true,
        speed: 2000, // Duration of slide transition in milliseconds
        slidesToShow: 6,
        slidesToScroll: 1,
        cssEase: "linear",
        autoplay: true,
        autoplaySpeed: 0, // Slide change interval in milliseconds
        // customPaging: (i) => <div className={styles.customDot}>{i + 1}</div>,
        // beforeChange: (_, next) => setActiveSlide(next),
    };

    return (
        <div className={style.ourpartners}>
            <div className={style.partnertext}>Trusted by 100+ growing businesses</div>

            <Slider {...settings}>
                {partnersArr.map((ele, index) => (
                    <div className={style.partnersslick} key={`${ele.name}-${index}`}>
                        <img className={style.partnersliderimages} size="huge" src={ele.image} alt={`${ele.name}-${index}`} />
                    </div>
                ))}
            </Slider>
        </div>
    )
};

export default OurPartners;
