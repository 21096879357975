import React, { useState } from "react";
import { withTranslation } from "react-i18next";
import { Button, Grid, Header, Icon, Image } from "semantic-ui-react";
import { useHistory } from "react-router-dom";
import Logo from "../../assets/images/Logo.svg";
import Reclytics from "../../assets/images/home/RECLYTICS.png";
import Speedrank from "../../assets/images/home/SPEEDRANK.png";
import InView from "../../assets/images/home/IN-VIEW.png";
import Speedcheck from "../../assets/images/home/SPEEDCHECK.png";
import Skillytics from "../../assets/images/home/SKILLYTICS.png";
import Talentics from "../../assets/images/home/TALENTICS.png";
import Humanize from "../../assets/images/home/HUMANIZE.png";
import Criya from "../../assets/images/home/CRIYA.png";
import Digital from "../../assets/images/home/Digital Onboarding Services.png";
import CampusLytics from "../../assets/images/home/CampusLyticsServiceHeader.png"
import styles from "./scss/Header.module.scss";

function DesktopHeader(props) {
  const { t, activeStatus, handleTab, headerMenuItems } = props;
  const [hovered, setHovered] = useState(false);

  const history = useHistory();
  const servicesList = [
    {
      name: "RECLYTICS",
      image: Reclytics,
      subname: "End to End<br />Recruitment Services",
      url: "reclytics",
    },
    {
      name: "SKILLYTICS",
      image: Skillytics,
      subname: "Dynamic<br />Skill Development",
      url: "skillytics",
    },
    {
      name: "SPEEDCHECK",
      image: Speedcheck,
      subname: "AI-powered<br />Background Verification",
      url: "speedcheck",
    },
    {
      name: "TALENTICS",
      image: Talentics,
      subname: "Competency Based<br />Resource Management",
      url: "talentics",
    },
    {
      name: "SPEEDRANK",
      image: Speedrank,
      subname: "AI-Powered Assessments<br />for Precise Hiring",
      url: "speedrank",
    },
    {
      name: "HUMANIZE",
      image: Humanize,
      subname: "Innovative<br />Employee Engagement",
      url: "humanize",
    },
    {
      name: "CRIYA",
      image: Criya,
      subname: "Transformative<br />Corporate Wellness",
      url: "criya",
    },
    {
      name: "ONBOARDEASE",
      image: Digital,
      subname: "Digital<br />Onboarding Services",
      url: "reclytics",
      section: "onboarding",
    },

    {
      name: "IN-VIEW",
      image: InView,
      subname: "Streamline<br />Interview Scheduling",
      url: "reclytics",
      section: "inview",
    },
    {
      name: "CAMPUSLYTICS",
      image: CampusLytics,
      subname: "Redefining Corporate-<br />Campus Collaboration",
      url: "campuslytics",
    },

    // Add more services as needed
  ];

  const handleServiceNavigation = (techSuite, section) => {
    setHovered(false);
    if (section) {
      history.push(`/${techSuite}?section=${section}`);
    } else {
      history.push(`/${techSuite}`);
    }
  };

  return (
    <div className={styles.DesktopHeader}>
      <img
        src={Logo}
        alt="logo"
        className={styles.logo}
        onClickCapture={() => handleTab("home")}
      />
      <div>
        {headerMenuItems.map((menuItem) => (
          <div
            key={menuItem.url}
            className={styles.menuItem}
            // onClickCapture={() => handleTab(menuItem.url)}
          >
            <span
              className={
                activeStatus === menuItem.url
                  ? styles.activeLink
                  : styles.headerLink
              }
              onClickCapture={() => handleTab(menuItem.url)}
              onMouseEnter={() =>
                menuItem.text === "services" && setHovered(true)
              }
              onMouseLeave={() =>
                menuItem.text === "services" && setHovered(false)
              }
              fontas="body"
            >
              {t(menuItem.text)}
            </span>
            {menuItem.text === "services" && hovered && (
              <div
                className={styles.servicesDropdown}
                onMouseEnter={() =>
                  menuItem.text === "services" && setHovered(true)
                }
                onMouseLeave={() =>
                  menuItem.text === "services" && setHovered(false)
                }
              >
                {servicesList.map((service) => (
                  <div
                    key={service.id}
                    className={styles.serviceItem}
                    onClick={() =>
                      handleServiceNavigation(service.url, service.section)
                    }
                  >
                    <div className={styles.serviceImageContainer}>
                      <img
                        src={service.image}
                        alt={service.name}
                        className={styles.serviceImage}
                      />
                    </div>

                    <div className={styles.textContainer}>
                      <span
                        className={styles.headtext}
                        dangerouslySetInnerHTML={{ __html: service.subname }}
                      ></span>
                      <span className={styles.subtext}>{service.name}</span>
                    </div>
                  </div>
                ))}
              </div>
            )}
          </div>
        ))}
      </div>

      <button
      className={styles.loginbtn}
        onClickCapture={() =>
          (window.location.href = "https://app.hrlytics.in/login")
        }
      >
      {t("login")}
      </button>
    </div>
  );
}

export default withTranslation("translations")(DesktopHeader);
