// import Traininganddev from '../../assets/images/hflFeature/Traininganddev.svg';
// import Endtoend from '../../assets/images/hflFeature/Endtoend.svg';
// import corporatewellness from '../../assets/images/hflFeature/corporatewellness.svg';
// import Employeeenage from '../../assets/images/hflFeature/Employeeenage.svg';
// import ComprehensiveBackgroundVerification from '../../assets/images/hflFeature/Comprehensive Background Verification.svg';
// import Resource from '../../assets/images/hflFeature/Resource.svg';
//slider
import Criya from 'assets/images/home/slider/Criya.webp';
import Humanize from 'assets/images/home/slider/Humanize.webp';
import SpeedCheck from 'assets/images/home/slider/SpeedCheck.webp';
import Skillytics from 'assets/images/home/slider/Skillytics.webp';
import Reclytics from 'assets/images/home/slider/Reclytics.webp';
import Talentics from 'assets/images/home/slider/Talentics.webp';
import SpeedRank from 'assets/images/home/slider/SpeedRank.webp';
import CampusLytics from 'assets/images/home/slider/CampusLyticsSlider.webp';

//sidebg
import HumanizeSBF from 'assets/images/home/side_bgs/Humanize.png';
import CriyaSBF from 'assets/images/home/side_bgs/Criya.png';
import SpeedCheckSBF from 'assets/images/home/side_bgs/SpeedCheck.png';
import SkillyticsSBF from 'assets/images/home/side_bgs/Skillytics.png';
import SpeedRankSBF from 'assets/images/home/side_bgs/SpeedRank.png';
import TalenticsSBF from 'assets/images/home/side_bgs/Talentics.png';
import ReclyticsSBF from 'assets/images/home/side_bgs/Reclytics.png';
import CampusLyticsSBF from 'assets/images/home/side_bgs/CampusLytics.png';


export const HomeJson = [
  {
    image: Reclytics,
    name: "Reclytics",
    url: "reclytics",
    sidebg: ReclyticsSBF,
    header: "End to End Recruitment Solution",
    content: "Skillfully Sourcing and Seamlessly Integrating New Talents.",
    desc: "Welcome to our comprehensive HR Tech platform designed to streamline and optimize your recruitment and onboarding processes. Below, you'll find an in-depth overview of the features that empower organizations to source, attract, select, and seamlessly integrate candidates into their workforce.",
  },
  {
    image: Skillytics,
    sidebg: SkillyticsSBF,
    name: "Skillytics",
    url: "skillytics",
    header: "Knowledge and Skill development",
    content: "Harness Your Technical Skills",
    desc: "Discover our all-encompassing HR Tech platform, engineered to enhance and streamline your organization's training and development initiatives. Below, we delve into the comprehensive features designed to empower your employees with unparalleled opportunities for skills enhancement, knowledge expansion, and professional growth."
  },
  {
    image: SpeedCheck,
    sidebg: SpeedCheckSBF,
    name: "SpeedCheck",
    url: "speedcheck",
    header: "Comprehensive Background Verification",
    content: "AI Powered  Fast, Secure, Easy Background Checks",
    desc: "Speedcheck is a cutting-edge AI-driven background verification platform designed to simplify and enhance your hiring process. We ensure workforce integrity with precise and reliable checks. Our platform offers a seamless and user-friendly experience, focusing on continuous communication and efficiency. By sharing knowledge and fostering skill development, we make background verification easy and effective. Trust Speedcheck for a streamlined, dependable, and hassle-free verification solution tailored to your needs."
  },
  {
    image: Talentics,
    sidebg: TalenticsSBF,
    name: "Talentics",
    url: "talentics",
    header: "Resource Management",
    content: "The Comprehensive Competency-Based Talent Management",
    desc: "A Platform to optimize the resources, streamline processes, and achieve organisational goals efficiently. It helps organizations allocate resources effectively by providing visibility into resource availability, skills, and capacity. This ensures that the right resources are assigned to the right projects at the right time."
  },
  {
    image: SpeedRank,
    sidebg: SpeedRankSBF,
    name: "SpeedRank",
    url: "speedrank",
    header: "Precision Assessments",
    desc: "SpeedRank by HRLytics is an advanced assessment service platform that offers a comprehensive evaluation of candidates through a wide variety of question types and activities. It is expertly tailored for diverse users such as corporate recruiters, government agencies, testing bodies, and educational institutions, ensuring relevance across multiple sectors. The platform not only facilitates precise candidate assessment but also specializes in creating longitudinal digital profiles(LDP).",
    content: "Precision Assessments for the Modern Era",
  },
  {
    image: Humanize,
    sidebg: HumanizeSBF,
    name: "Humanize",
    url: "humanize",
    header: "Scientific Employee Engagement",
    desc: "We revolutionize employee engagement by developing human skills and enhancing thinking abilities, including design thinking, creative thinking, critical thinking, and emotional intelligence. Our approach incorporates various creative expression mechanisms such as speaking, singing, reading, writing, movement, painting, crafts, theatre and art. This is achieved through the use of technology and AI for personalized, data-driven, and scientific engagement, ensuring outcome-oriented results.",
    content: "Revolutionizing Employee Engagement",
  },
  {
    image: Criya,
    sidebg: CriyaSBF,
    name: "Criya",
    url: "criya",
    header: "Corporate Wellness",
    content: "Empowering Employee Wellness with AI",
    desc: "Criya is a personalized app that transforms employee wellness through AI-driven insights and engaging activities. Designed to nurture the mind, encourage nature connection, and foster social ties, Criya offers a data-driven approach to enhance workplace well-being. With a focus on individualized experiences, it engages employees in a journey towards a healthier, more balanced life, making it an essential tool for organizations committed to building a vibrant, productive environment.",
  },
  {
    image: CampusLytics,
    sidebg: CampusLyticsSBF,
    name: "CampusLytics",
    url: "campuslytics",
    header: "Redefining Corporate-Campus Collaboration",
    content: "Redefining Corporate-Campus Collaboration",
    desc: "CampusLytics, a specialized platform from HRLytics, revolutionizes the way corporations engage with academic institutions, leveraging cutting-edge analytics to enhance recruitment and engagement strategies. This platform ensures a seamless integration of students into the corporate world, fostering mutual growth and preparing a new generation for the challenges of tomorrow.",
  },
];
