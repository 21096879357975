import React from "react";
import { Image } from "semantic-ui-react";
import style from "./scss/aboutplatform.module.scss";

const getGroupPng = (indx) => require(`assets/images/home/animation_group/Group${indx + 1}.png`);

const AnimateGroup = () => (
  <div className={style.checkALign}>
    {Array(4).fill(null).map((_, idx) => (
      <Image src={getGroupPng(idx)} key={idx} />
    ))}
  </div>
);

export default AnimateGroup;
