const en = {
  translations: {
    //home
    max: "Max",
    mizing_human: "mizing Human",
    resource_potential: "Resource Potential",
    learn_more: "Learn More",
    microProductsDetails: [
      { src: "RecLytics", name: "RecLytics", desc: "Recruitment Analytics" },
      { src: "Staffi", name: "Staffi", desc: "Staffing Intelligence" },
      { src: "Rics", name: "Rics", desc: "Manpower Maximization" },
      { src: "PPP", name: "PPP", desc: "Performance Mining" },
      { src: "DigiB", name: "DigiB", desc: "Digital Behavior Extracts" },
      {
        src: "Skillitics",
        name: "Skillitics",
        desc: "Skill Validations & Projections",
      },
      { src: "MyOrg", name: "MyOrg", desc: "Relationship Insights" },
      { src: "SpeedCheck", name: "SpeedCheck", desc: "BGV Correlations" },
    ],
    productsAndServices: [
      { src: "Precision check", name: "Precision check" },
      { src: "Degree Coverage", name: "Degree Coverage" },
      { src: "DigiJoin", name: "DigiJoin" },
      { src: "Speed Check", name: "Speed Check" },
      { src: "WeConnect", name: "WeConnect" },
      { src: "Better Humans", name: "Better Humans" },
    ],
    micro_products: "Micro Products",
    products_and_services: "Aligned Products and Services",
    our_products_and_services: "Our Products & Services",
    our_products_and_services_content:
      "HRLYTICS provides an entire gamut of services required for business optimization by maximizing human contribution proportional to human capabilities in core areas. HRLYTICS provides end-to-end HR analytics designed to provide appropriate information for critical decision making across a wide range of industries.",
    hr_analytics_in: "HR Analytics in",
    hr_analytics_in_buttons: [
      "Staffing",
      "Recruitment",
      "ManPower",
      "Engagement and Relationship",
      "Workforce Behavior in Digital Space",
      "Background",
      "Training and Development",
      "MyBest",
    ],
    hrlytics_the_necessity: "HRLytics the necessity",
    home_card_1:
      '"Correlation between BGVs and performance metrics are key parameters for efficient hiring"',
    person_1: "BGV Head",
    home_card_2:
      '"Inefficiency in Learning & Development, Substandard Talent Transformation and Clueless Upskilling is destroying entire Indian IT space. We need continuous validation systems that are machine driven"',
    person_2: "Global L&D Head",
    home_card_3: '"Current HRMS systems are age old concepts in digital era"',
    person_3: "HR Expert",
    home_card_4:
      '"Human Resource Management has been catastrophic in large organisations. Humanly impossible to correct it"',
    person_4: "Global TA Head",
    home_card_5:
      '"OMG!! Corporate recruitment system is most inefficient. It requires a complete overhaul"',
    person_5: "Global L&D Head",

    //header
    services: "Services",
    partner_products: "Partner Products",
    contact: "Contact",
    careers: "Careers",
    about_us: "About Us",
    cardNames: [
      {
        name: "PrecisionCheck",
        src: "Precision check",
        heading: "Assessment and Interview Services",
        content:
          "HRLytics has multiple partners providing to assessments in all major domains to check various knowledge and respective skill levels. Hrlytics would be a one stop solution for all assessment needs of an organisation. HRlytics also provides interview services through its AI based interview platform InView with recording and screen share features with detailed process and data captures.",
      },
      {
        name: "DegreeCheck",
        src: "Degree Coverage",
        heading: "College Accreditation and Ranking Services",
        content:
          "A unique offering providing accreditation services to corporate in rating and ranking colleges for engagements and recruitments. Hrlytics uses multiple modes and methodologies evolved from decades of research on academic systems.",
      },
      {
        name: "WeConnect",
        src: "WeConnect",
        heading: "Employee Engagement Services",
        content:
          "Hrlytics has associate partners to provide employee engagement services to improve retention and image building of organisations from small to big. This creates a beautiful internal social ecosystem to communicate, exchange and evolve.",
      },
      {
        name: "SpeedCheck",
        src: "Speed Check",
        heading: "Background and KYC Verification",
        content:
          "Hrlytics provides BGV services through its partner company SpeedCheck which is AI Powered, Fast, Secure and Easy Background Checks at reduced TAT. SpeedCheck provides Education Check, Employment Check, Drugs Check, Address Check, Criminal Records Check, KYC Check, CIBIL Verification, Database Check.",
      },
      {
        name: "DigiJoin",
        src: "DigiJoin",
        heading: "Digital Onboarding Services",
        content:
          "HrLytics has partnered with platforms to provide a smooth digital onboarding process through intelligent verification. It eases out the onboarding hassles and ensures precision data captures. It also detects any mismatch in claimed data in real time.",
      },
      {
        name: "BetterHumans",
        src: "Better Humans",
        heading: "Human Quality Enhancement",
        content:
          "HRlytics has partnered with human development companies to offer life training and practice models to evolve better human ecosystem within any organisation. HRlytics offer services to enhance human quality, values and principles within an organisation through creative blends.",
      },
    ],
    serviceDetails: [
      {
        side: "right",
        name: "Staffing",
        content:
          "Our Intelligent Staffing services powered with Staffi helps you optimise your TAT in fulfillment  and ready on toes to absorb any kind of business opportunity. Thus making staffing an integral part to ease and be confident of signing businesses.",
        src: "./Services/image1.webp",
      },
      {
        side: "left",
        name: "Recruitment",
        content:
          "RecLytics boosts your sourcing and recruitment system with thorough quality analytics. The main challenge faced by any organisation today is finding that 100% suitable resources fulfilling the short term and long term recruitment goals. Organisations are normally blinded by short term goals and end up carrying baggages. Our Reclytics services would help you achieve maximum fitment with long term benefits.",
        src: "./Services/image2.webp",
      },
      {
        side: "right",
        name: "ManPower",
        content:
          "The Manpower Analytics engine Rics helps in optimising resources thus plugging the cost leakage majorly. Efficient internal skills and talent analytics helps organisation to plan advance upskilling, maintaining validated skill profiles and thus maximally utilize the organisation manpower.",
        src: "./Services/image3.webp",
      },
      {
        side: "left",
        name: "Engagement and Relationship",
        content:
          "Human resource engagement analytics powered by MyOrg derived through objective surveys, micro assessments, titbit mindpecks, happiness testers etc. ensures reality checks to improve the organisation's ecosystem and human ambience. It helps further micro level engagements with human resources through personalised objectives. MyOrg also analyses the [Associate - Organisation] relationship measures from negative -1 to positive +1.",
        src: "./Services/image4.webp",
      },
      {
        side: "right",
        name: "Workforce Behavior in Digital Space",
        content:
          "Again powered by the MyOrg micro-product, we offer crawler services to extract workforce behaviour in social media to keep a check on their organization perceptions, retention quotient, reality behaviour checks, real time image checks. This helps in real time mitigations of perceptions.",
        src: "./Services/image5.webp",
      },
      {
        side: "left",
        name: "Background",
        content:
          "Our comprehensive AI powered BGV check results help employers reduce workplace violence, theft, substance abuse and negligent hiring liabilities at a reduced TAT. We comprehensively correlate BGV data with human resource performance and potential for effective hiring",
        src: "./Services/image6.webp",
      },
      {
        side: "right",
        name: "Training and Development",
        content:
          "Organisations fail to measure real outcomes whenever training and skilling programs are rolled out. Huge budgets get allocated for skilling and learning programs, but unless the effective outcomes of the programs are measured, there are undetected business losses and consequences. Powered by the Skillitics engine, we provide the best of the analytics support to gauge Learning, Skilling and Training programs for better outcomes, and mapping human resources to right projects. We also provide analytical support to analyze idle resources and create skilling road maps to them aligned to organisations projections.",
        src: "./Services/image7.webp",
      },
      {
        side: "left",
        name: "MyBest",
        content:
          "The MyBest micro product enables complete analytics of three key parameters of human resources in an organization - Participation, Performance and Proactiveness. The key parameters are captured through various measurements, surveys, feedbacks, timesheets, logs etc. This enables organisations to keep track of one associate one job check, round the clock participation in job, key outcomes to measure job performance and proactiveness in delivery.",
        src: "./Services/image8.webp",
      },
    ],
    products_and_services_offerings: "Products and Service Offerings",
    analytics_hr: "Analytics in HR",
    contact_us: "Contact Us",
    visit_us: "Visit Us",
    footer_address: [
      "HRLytics Private Ltd.,",
      "No. 362/7, Third Floor,",
      "16th Main Road, 4th T Block,",
      "Jayanagar, Bengaluru - 560041.",
    ],

    // contact us

    leave_us_a_message: "Leave us a Message",
    name: "Name",
    email_address: "Email Address",
    contact_us_address:
      "HRLytics Private Ltd., No. 362/7, Third Floor, 16th Main Road, 4th T Block, Jayanagar, Bengaluru - 560041.",
    your_message: "Your Message",
    send_btn: "Send",
    too_short: "Too Short!",
    too_long: "Too Long!",
    required: "Required",
    invalid_email: "Invalid email",

    // career

    "being_to_becoming…": "Being to Becoming…",
    desktop_career_header: "Careers @ HRLYTICS",
    mobile_career_header: "Careers",
    career_content_abstract1:
      "A place where you transition from being yourself to becoming your creative best!",
    career_content_abstract2:
      "At HRlytics you are always a part of the solution. An ideal place where you can be the change you wish to see.",
    career_content_abstract3:
      "If you have it in you to make the difference, come! Contribute and realize your true potential.",
    career_content_abstract4:
      "HRlytics is an organization that optimizes human potential. Your every minute is valued while you are counted upon.",
    career_content_abstract5:
      "HRlytics is only for those who believe that there is no end to betterment.",
    career_content_question1: "Do you believe work can be real fun? ",
    career_content_question2: "Should life be better? ",
    join_hrlytics_content: "Join HRlytics, let’s bring the change together! ",
    career_contact_content1: "Send in your application to ",
    career_contact_content2: " Reach out to  Miss. Muppana Tarani @ ",
    career_card_subheading:
      "More than the work we do, it is the way we do our work that makes all the difference",
    learning: "Learning",
    final_card_content:
      "We enjoy learning and for us, growth means learning. The lessons we learn at HRlytics will keep us steadfast and guide us towards success in all of our endeavors, be it professional or personal. Our assessment tools are used for self assessments so that we take necessary steps for self development. At HRlytics, learning is not an option but it is a consequence of working with sharp minds.",

    // about us

    why_us: "Why Us",
    about_us_subheading1:
      "The Productivity of Human Resources and Effectiveness of HR process are two key fundamentals for a successful organisation.",
    about_us_content1:
      "Human Driven HR space is bound to have it's deep defects. Unless a HR ecosystem is powered through efficient machine driven analytics, tools, products and tech enabled services, we can be well assured that the organisation's performance as a whole is depleted.",
    about_us_content2:
      "Sometime it would be a deeper dent that the organisation wouldn't realise on the severity.  We have seen many big organisations crashed in recent times due to resignations, attrition, staffing inefficiency, inappropriate hiring, inappropriate skilling, hasty onboarding, under utilisation of internal resources, blind eye towards internal talent, inferior utilisation of skilled resources, broken organisational skill roadmaps, clueless competency levels etc.",
    about_us_content3:
      "Just an installation of HRMS within the system is not the solution to overcome this massive problem.",
    about_us_content4:
      "It's time that HR space need to be managed, monitored, engaged, validated, optimised and utilised effectively through the aid of appropriate AI machines. Nevertheless we should also understand that the word AI is used abusively.",
    about_us_content5:
      "HRlytics is backed by renowned HR and AI researchers which makes HRlytics a formidable choice to get your HR space AI powered to sustain or build an efficient organisation.",
    about_us_content:
      "Our team comprises of a cosmopolitan and a highly sophisticated group of people with rich knowledge and extensive experience. Being HRLYTIANS, our core belief is “The quality of our People is the quality of our Organization.” Thus our people have shaped a rich organizational culture that is focused on deliverables, enrichment and growth.  As HRLYTIANS, our aim and focus is always CUSTOMER DELIGHT.  We love to see our customers’ smile.",
    logout: "Logout",
    no_data_found: "No Data Found",
    blog: "Blog",
    first_name: "First Name",
    last_name: "Last Name",
    email_address: "E-mail Address",
    posted_date: "Posted Date",
    linkedin_profile_url: "Linkedin Profile URL",
    status: "Status",
    view_blog: "View Blog",
    job_application: "Job Application",
    mobile_number: "Mobile Number",
    applied_date: "Applied Date",
    back: "Back",
    blog_content: "Blog Content",
    do_you_want_to_reject_this_block: "Do You Want To Reject This Block",
    no: "No",
    yes: "Yes",
    approve: "Approve",
    reject: "Reject",
    back_jobRequirement: "Back JobRequirement",
    job_title: "Job Title",
    logo: "Logo",
    choose_file: "Choose File",
    no_file_choosen: "No File Choosen",
    experience_details: "Experience Details",
    jobDescription: "JobDescription",
    location: "Location",
    apply_link: "Apply Link",
    notice_period: "Notice Period",
    save: "Save",
    add_new: "Add New",
    sNo: "S.No",
    modify: "Modify",
    view: "View",
    contacted: "Contacted",
    cancel: "Cancel",
    admin_login: "Admin Login",
    invalid_login: "Email or Password is incorrect",
    login: "Login",
    blog_and_job: "Blog and Job Applications",
    email_is_required: 'Email is Required',
    password_is_required: 'Password is Required',
    scroll_down: 'Scroll down',
    link: 'View Link',
    please_select_a_file: 'Please Select a file',
    abhilasha_mam_mail_id: 'abhilasha.gaonkar@hrlytics.in',
    info_mail_id: 'info@hrlytics.in',
    contact_us: 'Contact Us',
    hrlytics_private_ltd: 'HRLytics Private Ltd',
    third_floor: 'No. 362/7, Third Floor,',
    block_east: '16th Main, 4th T Block East,',
    jayanagar_bengaluru: 'Jayanagar, Bengaluru,',
    karnataka: 'Karnataka 560041',
    large_file_size: 'Large File Size',
    designation: 'Designation',
    blog_title: 'Blog Title',
    technology_based_tracks: 'Technology Based Assessments',
    show_less: 'Show Less',
    view_all: 'View All',
    role_based_track: 'Role Based Assessments',
    our_assessment_pack: 'Reclytics - Assessment Pack',
    our: 'Our',
    assessment: 'Assessment',
    pack: 'Pack',
    learning: 'Learning',
    certification: 'Certification',
    hrlytics_exclusive: 'HRLytics Exclusive',
    our_learning_pack: 'Skillytics - Learning Pack',
    our_certification_pack: 'LIT Certification',
    certified_professionals_content: 'A - Z, You name the company and You will find Our Certified Professionals.',
    close: 'Close',
    technologies: 'Technologies',
    call_now: 'Call Now',
    call_us_for_demo: 'Call Us for a Demo',
    sort_by: 'Sort By',
    digital_engineering: 'Digital Engineering',
    product_engineering: 'Product Engineering',
    manufacturing_engineering: 'Manufacturing Engineering',
    plant_engineering: 'Plant Engineering',
    it_technologies: 'IT Technologies',
    explore_more: 'Explore More',
    services_strategies_header: 'Strategies Outlined for Maximizing Human Potential',
    '5g': '5G',
    cax_automation: 'CAx Automation',
    security_solution: 'Artificial Intelligence & Machine Learning',
    sustaunable_smart_world: 'Blockchain',
    security_monitorings: 'Cloud Computing',
    cyber: 'Cybersecurity',
    data_science: 'Data Science & Analytics',
    sustainable_engg: 'Database Management',
    enterprises: 'Enterprise Technologies',
    programming_languages: 'Programming Languages',
    software_test: 'Software Testing',
    web_development: 'Web Development',
    ai_ml_engineer: 'AI/ML Engineer',
    backend_dev: 'Backend Developer',
    cloud_engineer: 'Cloud Engineer',
    data_scientist: 'Data Scientist',
    dba: 'Database Administrator (DBA)',
    frontend_dev: 'Frontend Developer',
    full_stack_dev: 'Full Stack Developer',
    qa_tester: 'Quality Assurance (QA) Tester',
    sys_admin: 'System Administrator',
    cybersecurire: 'Cybersecure',
    security_monitoring: 'Security Monitoring',
    security_solutions: 'Security Solution',
    security_service: 'Security Service',
    immersive_experiences: 'Immersive Experience',
    indusrtry: 'Industry 4.0',
    product_consulting: 'Product Consulting',
    sustaunable_smart_worlds: 'Sustainable Smart World',
    sustainable_engg: 'Sustainable Engineering',
    five_g: '5G',
    caecfd: 'CAE & CFD',
    software_engineering: 'Software Engineering',
    cloud_engineering: 'Cloud Engineering',
    devops: 'DevOps',
    embedded_systems: 'Embedded Systems',
    engineering_analytics: 'Engineering Analytics',
    integrated_design: 'Integrated Design, Validation & Testing',
    lab_service: 'Lab as a service',
    sustenance: 'Sustenance',
    testing_validation: 'Testing & Validation',
    user_experience: 'User Experience',
    vlsi: 'VLSI',
    voice_innovations: 'Voice Innovations',
    wearables_engineering: 'Wearables Engineering',
    accelarated_operations: 'Accelarated Operations',
    agile_suply_chain: 'Agile Suply Chain',
    content_engineering: 'Content Engineering',
    digital_factory: 'Digital Factory & Simulations',
    line_expansion: 'Line Expansion & Transfer',
    manufacturing_automation: 'Manufacturing Automation',
    new_product_development: 'New Product Development',
    plan_design: 'Plan Design & Engineering',
    plm: 'PLM on Cloud',
    sourcing: 'Sourcing & Procurement',
    cpes: 'CAPEX Project E/EPCM Services',
    devop: 'DevOps',
    mpm: 'Material & Parts Management',
    oe: 'Operational Excellence',
    psm: 'Plant Sustenance & Management',
    sp: 'Sourcing & Procurement',
    rce: 'Regulatory Compliance Engineering',
    reclytics: 'Reclytics',
    about_service_course: 'Welcome to our comprehensive HR Tech platform designed to streamline and optimize your recruitment and onboarding processes. Below, youll find an in-depth overview of the features that empower organizations to source, attract, select, and seamlessly integrate candidates into their workforce.',
    features: 'Features',
    scalability: 'Scalability',
    solution: 'Solution',
    what_we_do: 'What We Do?',
    home_content: "Harness the power of human capital with HRLytics' strategic HR Tech solutions. Unlock your team's full potential through data-driven insights, driving organizational growth with human-centered strategies from insight to action ",
    request_demo: 'Request Demo',
    get_certified: 'Get HRLytics Certified Today!!',
    lit_certification: 'LIT Certifications',
    hrlytics_exclusive: 'HRLytics Exclusives',
    ifl: 'INTELLIGENCE FOR LIFE (IFL)',
    ifl_header: 'A Criya Product',
    criya: 'Criya',
    criya_content1: 'A Product for Evolving a Self Managed and Self Disciplined HR Space in the New-Age Era where Human Minds are Invaded and distorted with Digitalization, Entertainment and Virtualization.',
    criya_content2: 'Enhances the Habits and Characteristics for',
    criya_content2_bold: 'Human Communication, Harmonious Socialising, Extroverted Living, Sensible Comprehensions, Perspective Thoughts, Creative Thinking, Critical Analysis, Philosophical Solutions, Purposeful Corporate Life, Corporate Lifestyles and Living, Organisation & Leadership Beliefs, Vision & Mission Goal Alignments & Achievements.',
    criya_content3: 'The IFL product and its key metrics, offer an enriching experience by targeting the roots of communication, human skills, behaviour, corporate life - the fundamental skills that have the ability to steer both an individual and the  organization towards success.',
    course_structure: 'Course Structure',
    enroll_now: 'Enroll Now',
    about_the_course: 'About the Course',
    course_objectives: 'Course Objectives',
    courses: 'Courses',
    payment_content: 'This program offers a deep dive into MongoDB, Express.js, React, and Node.js, equipping you with the skills to build dynamic web applications from the ground up.',
    sub_total_items: 'SUBTOTAL (1 ITEMS)',
    tax: 'TAX (18%)',
    discount: 'DISCOUNT',
    check_out: 'CHECK OUT',
    continue_shoping: 'CONTINUE SHOPPING',
    total_amount: 'TOTAL AMOUNT',

    // header
    thoughts: 'Thoughts',
    platform: 'Platform',
    services: 'Services',
    t_hive: 'T-Hive',
    lit_certification: 'LIT Certification',
    about_us: 'Careers',
    login: 'Login',

    // humanize
    humanize_sub_hero_content_1: 'Igniting Excellence: Enhancing Employee',
    humanize_sub_hero_content_2: 'Engagement at Every Stage',
    humanize_hero_header: 'The Future of ',
    humanize_hero_subheader:'Employee Engagement',
    humanize_hero_body: 'Revolutionizing Employee Engagement',
    what_is_humanize: 'What is Humanize?',
    employee_engagement: 'Employee Engagement',
    pre_onboard_engagement: 'Pre Onboard Engagement',
    freshers_transformation: 'Freshers Transformation',
    key_features: 'Key Features',
    how_humanize_works: 'How Humanize Works',
    our_offerings: 'Our Offerings',

    // thoughts
    thoughts_hero_content_1: 'Dive Deep',
    thoughts_hero_content_2: 'Explore Our Latest Blog Posts',
    thoughts_hero_content_3: 'Spark your knowledge with our thought-provoking blog posts.',

    streamline_your_hiring_with: 'Streamline Your Hiring with',
    education_check: 'Education Check',
    verify_qualifications_ensure_compliance_and_build_a: 'Verify qualifications, ensure compliance, and build a',
    stronger_workforce_all_in_one_place: 'stronger workforce - all in one place.',
    speedcheck_carousel_content_1: 'Tailored Verification Solutions for Your Needs',
    speedcheck_carousel_content_2: 'Know Your Candidates Better AI Delivers Comprehensive Performance Metrics',
    what_is_speedcheck: 'What is Speedcheck',
    speedcheck_description: 'Speedcheck is a cutting-edge AI-driven background verification platform designed to simplify and enhance your hiring process. We ensure workforce integrity with precise and reliable checks. Our platform offers a seamless and user-friendly experience, focusing on continuous communication and efficiency. By sharing knowledge and fostering skill development, we make background verification easy and effective. Trust Speedcheck for a streamlined, dependable, and hassle-free verification solution tailored to your needs.',
    what_we_do: 'What We Do?',
    education_check_description: 'Verify candidate degrees and certifications directly, ensuring complete confidence in their qualifications.',
    employment_check: 'Employment Check',
    employment_check_description: 'Verify past jobs and experience eliminating resume inaccuracies so you can ensure authenticity and focus on the perfect fit.',
    drug_check: 'Drug Check',
    drug_check_description: 'Thorough drug screening, reducing legal headaches and financial burdens from drug use in your company.',
    cibil_verification_check: 'CIBIL Verification Check',
    cibil_verification_check_description: 'Analyze detailed credit history reports to assess financial responsibility and predict reliable candidates.',
    criminal_record_check: 'Criminal Record Check',
    criminal_record_check_description: 'Conduct extensive checks on criminal records and global watchlists, ensuring a safe and secure environment.',
    reference_check: 'Reference Check',
    reference_check_description: 'Quickly verify references using structured questions to obtain genuine feedback, ensuring confidence in your decisions.',
    database_check: 'Database Check',
    database_check_description: 'Our global and Indian search covers financial, regulatory, criminal, and compliance records, ensuring thorough checks. We verify personal, educational, employment details, and assess criminal and reputational risks.',
    address_verification_check: 'Address Verification Check',
    address_verification_check_description: 'Conduct thorough geo and physical address verifications to confirm identities and ensure accurate onboarding.',
    why_speedcheck: 'Why SpeedCheck',
    lightning_speed: 'Lightning Speed',
    lightning_speed_description: 'Verify the authenticity of a candidate’s educational and professional qualifications',
    domain_expertise: 'Domain Expertise',
    domain_expertise_description: 'Our team experts have worked across sectors and will devise a plan that best fits your company.',
    integrations: 'Integrations',
    integrations_description: 'With the most technical integrations in the industry, we are poised to be efficient and effective.',
    unmatched_security: 'Unmatched Security',
    unmatched_security_description: 'Verify the authenticity of a candidate’s educational and professional qualifications',
    compliance: 'Compliance',
    compliance_description: 'Uphold industry standards for trust and reliability, ensuring customer satisfaction.',
    safety_security: 'Safety Security',
    safety_security_description: 'Our systems are SSL encrypted and stress tested to ensure highest level of security standards',
    customer_experience: 'Customer Experience',
    customer_experience_description: 'We are committed to work together to ensure a positive experience for the end user.',
    technology_first: 'Technology First',
    technology_first_description: 'Our API’s enables large and complex organisations to run checks as scale with easy',
    comprehensive_screening:'Comprehensive Screening',
    comprehensive_screening_description:'Deliver a robust customer experience with thorough vetting processes.',
    best_in_industry_support: 'Best in Industry Support',
    best_in_industry_support_description: 'Experience support that sets the industry standard. Our dedicated team delivers unparalleled expertise and responsiveness, ensuring your needs are met with precision and care. Trust us for support that exceeds expectations.',
    reclytics_carousel_content_1_1: 'Assign assessments to candidates',
    reclytics_carousel_content_1_2: 'instantly with a single click.',
    reclytics_carousel_content_2_1: 'Know Your Candidates Better AI',
    reclytics_carousel_content_2_2: 'Delivers Comprehensive Performance Metrics',
    reclytics_carousel_content_3_1: 'Beyond Words Assess Skills',
    reclytics_carousel_content_3_2: 'Through Engaging Conversations.',
    complete_recruitment: 'Complete Recruitment',
    solutions_at_your_fingertips: 'Solutions at Your Fingertips',
    reclytics_carousel_des_1: 'Navigate the entire recruitment process with ease',
    reclytics_carousel_des_2: 'from sourcing to onboarding, Reclytics powers your',
    reclytics_carousel_des_3: 'journey with precision.',
    candidate_corner: 'Candidate Corner',
    explore_opportunities: 'Explore Opportunities',
    candidate_corner_1: 'Let us connect you with',
    candidate_corner_2: 'Top Companies and your Dream Job',
    explore_more_jobs: 'Explore More Jobs',
    what_is_reclytics: 'What is Reclytics',
    reclytics_description: 'Reclytics, powered by HRLytics, is a tech-enabled recruitment service designed to revolutionize the hiring process with speed and precision. Our platform leverages advanced technology and AI to deliver a data-driven and cost-effective recruitment solution that maximizes productivity.',
    auto_sourcing: 'Auto-Sourcing',
    approaching_for_fresher_requirements_with_clients: 'Approaching for JDs/Fresher Requirements with Clients',
    auto_sourcing_content_1: 'Work closely with clients to capture detailed job descriptions and fresher requirements, ensuring clarity from the start.',
    getting_fr_from_the_client: 'Getting JDs/FR from the Client',
    auto_sourcing_content_2: 'Receive and refine job descriptions directly from clients, streamlining the process of establishing role expectations.',
    integration_with_job_boards_and_social_media: 'Integration with Job Boards and Social Media',
    auto_sourcing_content_3: 'Extend your reach by seamlessly connecting with major job boards and social media platforms, expanding your talent pool.',
    ai_powered_candidate_matching: 'AI-powered Candidate Matching',
    auto_sourcing_content_4: 'Employ AI to match candidates to positions perfectly aligned with their skills and potential role compatibility.',
    automated_candidate_sourcing: 'Automated Candidate Sourcing',
    auto_sourcing_content_5: 'Harness advanced automation to identify and attract top talent from a diverse range of sources including T-Hive, campus drives, pool drives, and direct applications.',
    integration_ats: 'Integration with Applicant Tracking Systems (ATS)',
    auto_sourcing_content_6: 'Streamline recruitment by syncing seamlessly with your ATS for efficient candidate management.',
    assessments: 'Assessments',
    speedRank_assessments: 'SpeedRank Assessments',
    auto_sourcing_assess_content_1: 'Utilize our SpeedRank technology for rapid and effective candidate assessments, ensuring only the most suitable candidates move forward.',
    real_time_coding_environment_adaptive_testing_multimodal_assessments: 'Real-Time Coding Environment Adaptive Testing & Multimodal Assessments',
    auto_sourcing_assess_content_2: 'Implement dynamic assessments that adjust in difficulty and vary in type from quizzes to simulations to accurately gauge candidate skills.',
    interview_process: 'Interview Process',
    real_time_coding_environment: 'Real-Time Coding Environment',
    auto_sourcing_inter_content_1: 'Provide candidates with a state-of-the-art coding platform to demonstrate their skills live, supporting multiple programming languages.',
    automated_interview_scheduling: 'Automated Interview scheduling (In-View)',
    auto_sourcing_inter_content_2: 'Automate scheduling to minimize delays and enhance the efficiency of the interview process.',
    feedback_mechanism: 'Feedback Mechanism',
    auto_sourcing_inter_content_3: 'Collect actionable insights to improve learning experiences continuously.',
    onboarding_and_offer_letter_management: 'Onboarding and Offer Letter Management',
    offer_management_documentation: 'Offer Management & Documentation',
    auto_sourcing_onb_content_1: 'Automate the offer process, from management to documentation, ensuring a smooth transition to onboarding.',
    background_verification: 'Background Verification (BGV)',
    auto_sourcing_onb_content_2: 'Conduct thorough background checks to maintain security and compliance standards.',
    engagement_onboarding: 'Engagement & Onboarding',
    auto_sourcing_onb_content_3: 'Engage new hires through targeted tech, domain, and company-specific onboarding programs to ensure they are fully integrated into their new roles.',
    mobile_accessibility: 'Mobile Accessibility',
    auto_sourcing_onb_content_4: 'Enable candidates and recruiters to interact with the platform anytime, anywhere, ensuring continuous engagement and process management.',
    data_privacy_compliance: 'Data Privacy Compliance',
    auto_sourcing_onb_content_5: 'Uphold strict compliance with data privacy laws, securing candidate information throughout the recruitment process.',
    tech_support: 'Tech Support',
    auto_sourcing_onb_content_6: 'Provide uninterrupted access to technical support, ensuring your recruitment operations are always running smoothly.',
    high_speed_reduced_recruitment: 'High-Speed, Reduced TAT Recruitment',
    rapid_candidate_sourcing: 'Rapid Candidate Sourcing',
    tat_content_1: 'Utilize AI-powered algorithms to quickly and accurately source top talent.',
    efficient_mobile_enabled_sourcing: 'Efficient Mobile-Enabled Sourcing',
    tat_content_2: 'Reach candidates anytime, anywhere with our intelligent mobile-enabled sourcing tools.',
    streamlined_assessments: 'Streamlined Assessments',
    tat_content_3: 'Implement micro and nano assessments for swift and precise candidate evaluation.',
    proctoring: 'Proctoring',
    tat_content_4: 'Offer structured programs to achieve professional qualifications and certifications.',
    auto_evaluation: 'Auto Evaluation',
    tat_content_5: 'Revolutionize assessments with automated tools for enhanced skill development.',
    assessment_analytics_and_insights: 'Assessment Analytics and Insights',
    tat_content_6: 'Gain insights to enhance recruitment decisions and strategies.',
    ai_enhanced_interview_process: 'AI-Enhanced Interview Process',
    ai_enhanced_interview_process_content: 'InView streamlines interviews with real-time coding, seamless communication, and efficient candidate tracking, all in one platform. Optimize your hiring process with InView.',
    adaptive_interviews: 'Adaptive Interviews',
    ai_enhanced_interview_process_content_1: 'Conduct data-driven adaptive interviews that evolve based on real-time data.',
    conversational_interviews: 'Conversational Assessments',
    ai_enhanced_interview_process_content_2: 'Engage candidates in natural, conversational assessments and interviews.',
    interviewer_co_pilot: 'Interviewer Co-Pilot',
    ai_enhanced_interview_process_content_3: 'Equip interviewers with AI-powered tools to enhance the interview experience and decision-making.',
    comprehensive_feedback_mechanism: 'Comprehensive Feedback Mechanism',
    ai_enhanced_interview_process_content_4: 'Capture feedback from candidates and interviewers to continuously improve the recruitment process.',
    tailored_pre_onboard_engagement: 'Tailored Pre-Onboard Engagement',
    ai_enhanced_interview_process_content_5: 'Engage candidates with personalized content and interactions before their first day.',
    seamless_onboarding_and_beyond: 'Seamless Onboarding and Beyond',
    digital_onboarding_solutions: 'Digital Onboarding Solutions',
    ai_enhanced_interview_process_content_6: 'Simplify the onboarding process with comprehensive digital tools.',
    through_background_verification: 'Through Background Verification (BGV)',
    ai_enhanced_interview_process_content_7: 'Evaluate candidates technical and domain-specific skills with advanced assessment tools.',
    speed: 'Speed',
    why_reclytics_content_1: 'Reduce turnaround time (TAT) and accelerate the recruitment process.',
    precision: 'Precision',
    why_reclytics_content_2: 'Make data-driven and informed hiring decisions with precision tools and analytics.',
    cost_efficiency: 'Cost Efficiency',
    why_reclytics_content_3: 'Achieve significant cost savings through optimized recruitment processes',
    productivity: 'Productivity',
    why_reclytics_content_4: 'Maximize productivity by hiring the right talent quickly and  efficiently',
    why_choose_reclytics: 'Why Choose Reclytics ?',
    ai_powered_fast_secure_easy: 'AI Powered  Fast, Secure, Easy',
    background_checks: 'Background Checks',
    enhance_your: 'Enhance Your',
    professional_profile_with: 'Professional Profile with',
    the: 'the',
    lit_certificate: 'LIT Certificate',
    elevate_your_position_in_a_dynamic_business_world: 'Elevate Your Position in a Dynamic Business World',
    what_is_lit_certifications: 'What is LIT Certifications?',
    lit_certifications_description: "LiT Certification is not a traditional training program but rather a set of meticulously crafted assessments that allow professionals to demonstrate their expertise. With multiple attempts available, the certification process is designed to push candidates to their limits, ensuring that only those with true mastery can earn the certification. Each assessment is conducted under strict proctoring conditions, ensuring the integrity and value of the certification.",
    lit_certification_key_features_content_1: 'Tailored for ambitious college students and forward-thinking corporate professionals eager to upscale their skills.',
    lit_certification_key_features_content_2: 'The program focuses on technological innovation and leadership excellence, aiming to equip participants with the knowledge and skills needed to thrive in dynamic, tech-driven environments.',
    lit_certification_key_features_content_3: 'The curriculum covers a wide range of topics relevant to the digital age, including but not limited to, emerging technologies, digital transformation strategies, leadership principles, and innovation methodologies.',
    lit_certification_key_features_content_4: 'The program emphasizes practical, hands-on experience real-world assessments. This approach ensures that participants not only understand concepts but can also demonstrate their proficiency in assessments relevant to their field.',
    lit_certification_key_features_content_5: 'The program is developed in collaboration with industry experts to ensure its relevance and alignment with current industry practices and assessment standards.',
    lit_certification_key_features_content_6: 'Successful completion of the program not only earns participants a valuable accreditation but also serves as a catalyst for personal and professional transformation, opening doors to new career opportunities and advancement.',
    lit_certification_key_features_content_7: 'The program offers customizable learning paths, allowing participants to adapt their studies to focus on specific assessment methodologies and areas of interest.',
    college_students: 'College Students',
    corporate_associates: 'Corporate Associates',
    lit_certification_college_students_content: "In today's competitive job market, where standing out is essential, the LIT Certificate for Students offers a transformative opportunity. Designed for all the students, this program provides a competitive edge by imparting essential skills and knowledge that are in high demand in the modern workforce.This immersive learning experience not only enhances their employability but also prepares them to be trailblazers in their careers from day one. By earning the LIT Certificate, students demonstrate their commitment to personal and professional development, setting themselves apart as forward-thinking individuals ready to make a meaningful impact in the digital age.Start your journey to becoming a future leader today with Our Certification Program.",
    lit_certification_corporate_associates_header: 'Enhance Your Professional Profile with the LIT Certificate Elevate Your Position in a Dynamic Business World',
    lit_certification_corporate_associates_content: 'In the fast-paced corporate world of today, maintaining a competitive edge requires constant adaptation. The LIT Certificate is tailored for proactive professionals dedicated to certifying their skills and propelling their careers through ongoing learning. This accreditation not only validates your abilities but also strategically fortifies your career path and establishes you as a trailblazer in your field.',
    why_the_lit_certificate: 'Why the LIT Certificate?',
    differentiate_yourself_in_job_applications: 'Differentiate Yourself in Job Applications',
    lit_certification_college_students_content_1: 'Amidst a large pool of graduates, the LIT Certificate distinguishes you, showcasing your verified skills and preparedness for professional roles.',
    skill_benchmarking: 'Skill Benchmarking',
    lit_certification_college_students_content_2: 'The assessment provides a valuable benchmark, helping you understand your proficiency in industry-relevant skills, and enabling you to identify areas for enhancement.',
    immediate_industry_acknowledgement: 'Immediate Industry Acknowledgement',
    lit_certification_college_students_content_3: 'The Certificate instantly acknowledges your skills, making your transition into your desired career smoother and improving your prospects in competitive job markets.',
    professional_edge: 'Professional Edge',
    lit_certification_college_students_content_4: 'Gain a competitive advantage with the Certificate, demonstrating your commitment to ongoing learning and professional development.',
    enhanced_employability: 'Enhanced Employability',
    lit_certification_college_students_content_5: 'The LIT Certificate enhances your employability by showcasing your skills, knowledge, and readiness for real-world challenges.',
    career_growth: 'Career Growth',
    lit_certification_college_students_content_6: 'Achieving certification through the LIT program not only validates your skills but also enhances your credibility and opens doors to new career opportunities.',
    skill_validation: 'Skill Validation',
    lit_certification_corporate_associates_content_1: 'Amidst a large pool of graduates, the LIT Certificate distinguishes you, showcasing your verified skills and preparedness for professional roles.',
    career_advancement: 'Career Advancement',
    lit_certification_corporate_associates_content_2: 'The assessment provides a valuable benchmark, helping you understand your proficiency in industry-relevant skills, and enabling you to identify areas for enhancement.',
    professional_upskilling: 'Professional Upskilling',
    lit_certification_corporate_associates_content_3: 'The Certificate instantly acknowledges your skills, making your transition into your desired career smoother and improving your prospects in competitive job markets.',
    recognition_of_expertise: 'Recognition of Expertise',
    lit_certification_corporate_associates_content_4: 'Gain a competitive advantage with the Certificate, demonstrating your commitment to ongoing learning and professional development.',
    strategic_professional_development: 'Strategic Professional Development',
    lit_certification_corporate_associates_content_5: 'The LIT Certificate enhances your employability by showcasing your skills, knowledge, and readiness for real-world challenges.',
    networking_oppurtunities: 'Networking Oppurtunities',
    lit_certification_corporate_associates_content_6: 'Achieving certification through the LIT program not only validates your skills but also enhances your credibility and opens doors to new career opportunities.',
    certificates_based_on_domains: 'Certificates based on Domains',
    CSE: 'CSE',
    MECH: 'MECH',
    EEE: 'EEE',
    ESC: 'ESC',
    it_iechnologies: 'IT Technologies',
    digital_engineering: 'Digital Engineering',
    product_engineering: 'Product Engineering',
    manufacturing_engineering: 'Manufacturing Engineering',
    plant_engineering: 'Plant Engineering',
    operationalizing_talentics_for_maximum: 'Operationalizing Talentics for Maximum',
    efficiency_and_talent_success: 'Efficiency and Talent Success',
    operationalizing_talentics_description: 'Talentics is designed to seamlessly integrate into your organization, ensuring that your talent management processes are not only efficient but also aligned with your long-term strategic goals. Here’s how Talentics guarantees the success of your talent management initiatives:',
    seamless_integration_and_implementation: 'Seamless Integration and Implementation',
    quick_onboarding_process: 'Quick Onboarding Process: ',
    user_friendly_interface_and_comprehensive_training_materials: 'User-friendly interface and comprehensive training materials.',
    api_integration: 'API Integration: ',
    easy_integration_with_existing_hr_systems_for_a_seamless_data_flow: 'Easy integration with existing HR systems for a seamless data flow',
    message_from_ceo: 'A Message from Our CEO : Shaping HR\'s Future',
    welcome: 'Welcome to HRLytics',
    ceo_message_content_1: "Where Potential Meets Excellence.",
    ceo_message_content_2: "I’ve guided my team to create a website that is not only informative and engaging but also participatory. The rapid and dramatic changes we’ve witnessed over the past decade have been astonishing, alarming, and incredibly intriguing. Designing a modern system for managing human resources amidst the pervasive advancements in digital technology, AI, and computing has become a fascinating challenge.",
    ceo_message_content_3: "In this era of unprecedented change, discerning the right policies from the wrong ones is no easy task. That's why we founded HRLytics—an initiative aimed at building an adaptable HR Tech platform focused on maximizing human potential through personalized attention and enhancing organizational productivity.  HRLytics combines cutting-edge sourcing and recruitment technology with AI-powered interactive assessment engines for skill and talent profiling, competency mapping, and skill alignment with the organization's vision and business roadmap.",
    ceo_message_content_4: "Our platform achieves this by seamlessly integrating with various systems, adding significant value to your organization.",
    ceo_message_content_5: `We have assembled a team of proficient advisors and researchers specialised in EdTech . I warmly invite you to share your thoughts on this visionary HR ecosystem. Your insights are priceless to us, and we look forward to publishing them in our microblogs as we shape HRLytics into a mature and robust solution. Together, we can create a platform that truly maximizes human potential.`,
    contribute_to_microblogs: 'Contribute to Microblogs',
    our_thoughts: 'Our Thoughts',
    community_voices: 'Community Voices',
    share_your_thoughts: "Share your Thoughts",
    submit: 'Submit',
    company_name: 'Company Name',
    your_name: 'Your Name',
    thumbnail: 'Thumbnail',
    heading: 'Heading',
    campusLytics_hero_title: 'CampusLytics',
    campusLytics_hero_sub_title: 'Redefining Corporate-Campus Collaboration',
    
    thive_hero_title: 'T-Hive - The Talent Hive',
    thive_hero_sub_title: 'The “Assessed Talent“ MarketPlace',
    thive_hero_description: 'The Repository of A-Z Skilled Candidates on Demand',
    connect_with_our_team: 'Connect with Our Team',
    five_mins_promise: '5 Mins Promise',
    upload_your_resume: 'Upload Your Resume',
    upload_your_job_description: 'Upload Your Resume Job Description',
    candidates: 'Candidates',
    candidates_description_1: 'Upload Your Resume Now,',
    candidates_description_2: 'Get Interview Calls within 5 Mins',
    employees: 'Employees',
    employees_description_1: 'Upload Your JD Now,',
    employees_description_2: 'Get Candidates Applications within 5 Mins',
    what_we_do: 'What We Do?',
    what_thive_do_content: 'At THive, we revolutionize the recruitment process by seamlessly connecting top-tier talent with the best opportunities. Developed by HRLytics Pvt Ltd, our platform ensures a streamlined hiring process for corporate organizations and startup companies alike, offering unmatched speed and efficiency in Talent Acquisition. With millions of meticulously assessed candidate profiles, THive is your ultimate solution for finding the perfect hire.',
    emerging_job_roles: 'Emerging Job Role Skills Assessed',
    marketplace_comparison_title: 'Assessed Talent Marketplace vs. Profile-Only Marketplace',
    our_marketplace_title: 'Assessed Talent Marketplace (T-Hive)',
    other_marketplace_title: 'Profile-Only Marketplace (Other Hiring Portals)',
    showcase_mastery: 'Showcase Your Mastery with Precision',
    showcase_mastery_content: 'In today’s competitive engineering landscape, standing out to potential employers requires more than just a degree. Employers are increasingly looking for graduates who can demonstrate not only technical expertise but also soft skills, problem-solving abilities, and a continuous commitment to learning. This is where the Longitudinal Digital Profile (LDP) offered by SpeedRank Assessments becomes a game-changer.',
    comprehensive_skill:"Overall Notes",
    comprehensive_skill_content:"The student shows great potential in both domain-specific and problem-solving skills. With a bit more focus on language proficiency and soft skills, they are well-positioned for a successful academic journey and future career readiness. Continued practice and engagement in project-based learning will further strengthen their profile for placement opportunities and higher education pathways.",
    what_is_LDP: 'What is a Longitudinal Digital Profile (LDP)?',
    what_is_LDP_content: 'The LDP Assessment Pack spans multiple semesters in a higher education framework, customized based on the learner\'s study year. It functions as a service for institutions to integrate HRLytics as an external quality check service partner for their learning and skill ecosystem. HRLytics creates a digital portfolio from assessment scores, connecting deserving students with placement and higher education opportunities. The AI-enabled assessment process ensures validity and transparency. The assessments cover multiple domains and specializations across higher education.',
    our_mission1: 'Elevating Campus Relations',
    our_mission_content1: 'Welcome to CampusLytics, a strategic branch of HRLytics dedicated to modernizing and enhancing the relationship between corporations and academia. By harnessing advanced analytics and scientific methods, we create tailored engagement strategies that align precisely with educational trends and corporate demands, ensuring a mutual growth trajectory for students and businesses alike.',
    our_mission: 'Our Mission',
    our_mission_content: 'To leverage advanced AI technologies and comprehensive candidate assessments to create a seamless and efficient recruitment experience, bridging the gap between employers and top-tier talent.',
    services_description: 'Our AI-driven talent solutions empower growth and success by prioritizing personalized learning, skill enhancement, and well-being. With a vast database of over 1 million and high-speed AI sourcing, we ensure swift matches. From digital onboarding to AI- powered background verification, we streamline talent acquisition, enabling you to focus on building a skilled and engaged workforce. Our technical skilling programs enhance competencies for holistic growth, while promoting a positive work environment and corporate wellness.',
    employers: 'Employers',
    kyc_check: 'KYC Check',
    kyc_check_content: "Conduct thorough verification of identity documents like Voter's ID, Aadhar, and Passport to ensure their authenticity and reliability.",
    remote_work_readiness: 'Remote Work Readiness',
    remote_work_readiness_content: `Assess candidates remote work capabilities by verifying their experience and essential skills for virtual roles.`,
    mental_health_and_wellness_check: 'Mental Health And Wellness Check',
    mental_health_and_wellness_check_content: 'Assess mental health practices, participation in wellness programs, and involvement in awareness activities to ensure overall well-being.',
    human_integrity_check: 'Human Integrity Check',
    human_integrity_check_content: 'Utilize advanced mobile assessments to measure key human skills, ensuring integrity.',
    about_us: 'About Us',
    welcome_description: 'Thank you for reaching us and joining us on this exciting journey. Together, we are a team that is driven by passion, creativity, and a shared commitment to making a positive impact in the world through our HR Tech software engines and services. Welcome to Team HRlytics.',
    cse: 'Computer Science and Engineering',
    mech: 'Mechanical Engineering',
    eee: 'Electrical and Electronics Engineering',
    ece: 'Electronics Skill Courses',
    it_industry: 'IT Technologies',
  },
};

export default en;
