import React, { useRef, useState } from "react";
import { useHistory } from "react-router-dom";
import Slider from "react-slick";
import { Button, Image } from "semantic-ui-react";
import styles from "./scss/HRLyticsPage.module.scss";

export default function HomeSlider({ HomeJson }) {
  const [activeSlide, setActiveSlide] = useState(0);
  const history = useHistory();

  const sliderRef = useRef();

  const isMobileView = window.innerWidth < 768;

  const settings = {
    dots: false,
    infinite: true,
    speed: 1000,
    slidesToShow: 1,
    slidesToScroll: 1,
    cssEase: "linear",
    autoplay: false,
    autoplaySpeed: 3000,
    beforeChange: (_, next) => setActiveSlide(next),
    arrows: false,
  };

  const handleMouseEnter = (idx) => {
    if (sliderRef.current) {
      sliderRef.current.slickGoTo(idx, true);
      // setActiveSlide(idx);
    }
  };

  const gotopiece = (i) => {
    setActiveSlide(i);
    history.push(`/${HomeJson[i].url}`)
  };

  return (
    <>
      {!isMobileView && (
        <div className={styles.homeSlider}>
          <div>
            <span fontas="header body">{HomeJson[activeSlide].name}</span>
            <p fontas="body">{HomeJson[activeSlide].header}</p>
          </div>
          <div className={styles.activeSlideRow}>
            {HomeJson.map(({ name, header }, idx) => (
              <span
                key={name}
                active={activeSlide === idx && "true"}
                onClickCapture={() => gotopiece(idx)}
                onMouseEnter={() => handleMouseEnter(idx)}
              >
                {name}
              </span>
            ))}
          </div>
          <Slider {...settings} ref={sliderRef}>
            {HomeJson.map(({ name, header, image }) => (
              <div key={name} className={styles.eachCard}>
                {/* <div> */}
                <Image
                  // wrapped
                  src={image}
                  alt={name}
                />
                {/* <div> */}
                {/* <Button
                  content="Know More"
                  basic
                  fontas="body"
                  onClick={() => history.push(`/${HomeJson[activeSlide].name}`)}
                /> */}
                {/* </div> */}
                {/* </div> */}
              </div>
            ))}
          </Slider>

        </div>
      )}
    </>
  );
}
